<template>
  <div>
    <div
      class="carousel-container rounded"
      @mouseover="pauseCarousel"
      @mouseleave="resumeCarousel"
    >
      <div class="carousel">
        <div
          v-if="((isATeacher || isAStudent) && !hideBadgeBar) || rewardsFetchRoutes.includes(currentRoute)"
          key="0"
          class="carousel-item"
          :class="{ active: currentIndex === visibleIndex(0) }"
        >
          <statistic-card
            :card-options="{ title: 'award-statistics' }"
            :data="awardStatsData"
            :student-id="getUserId()"
          />
        </div>
        <div
          v-if="marketplaces && marketplaces.length"
          key="1"
          class="carousel-item"
          :class="{ active: currentIndex === visibleIndex(1) }"
        >
          <div class="p-0 marketplace-card">
            <h4
              id="shakemessage"
              v-b-modal="`new-product-modal`"
              :class="$store.getters['appConfig/isDarkMode'] && 'btn-light-dark'"
            >
              {{ $t('labels.market') }}
            </h4>
          </div>
        </div>
        <div
          v-if="!hideStudentStats"
          key="2"
          class="carousel-item"
          :class="{ active: currentIndex === visibleIndex(2) }"
        >
          <student-statistic />
        </div>
        <div
          v-if="showSlpStatistic"
          key="3"
          class="carousel-item"
          :class="{ active: currentIndex === visibleIndex(3) }"
        >
          <Statistics
            :statistics-data="statisticsData"
          />
        </div>
      </div>

      <!-- Navigation Arrows -->
      <button class="carousel-arrow up"
              @click="prevSlide"
      >
        &#9650;
      </button>
      <button class="carousel-arrow down"
              @click="nextSlide"
      >
        &#9660;
      </button>

      <!-- Indicators -->
      <!-- <div class="carousel-indicators mx-0">
        <span
          v-for="(slide, index) in visibleSlides"
          :key="index"
          class="indicator"
          :class="{ active: currentIndex === index }"
          @click="goToSlide(index)"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import StatisticCard from '@/views/common/dashboard/StatisticCard.vue'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import {
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
} from '@/const/userType'
import StudentStatistic from './StudentStatistic.vue'
import useLessonDashboard from './lesson-dashboard/useLessonDashboard'
import Statistics from './lesson-dashboard/Statistics.vue'

export default {
  components: {
    StatisticCard,
    StudentStatistic,
    Statistics,
  },
  setup() {
    const {
      statisticsData, getStudentStatisticData,
    } = useLessonDashboard()
    getStudentStatisticData()
    return {
      isAStudent: getUserData() && getUserData().usertype === USER_TYPE_STUDENT,
      isATeacher: getUserData() && getUserData().usertype === USER_TYPE_TEACHER,
      statisticsData,
    }
  },
  data() {
    return {
      awardStatsData: [],
      isProccessing: false,
      self: getUserData(),
      isHighLevel: isHighLevelUser(),
      rewardsFetchRoutes: [
        'user-profile',
        `student-dashboard-for-${getUserData().usertype}`,
        'rewards-detail',
        `${getUserData().usertype}-student-diary`,
      ],
      currentIndex: 0,
      items: ['Slide 1', 'Slide 2'], // Your slides content
      interval: null,
      marketplaces: null,
    }
  },

  computed: {
    ...mapGetters('appConfig', { refreshRewardStats: 'getRefreshRewardStats', schoolSettings: 'getSchoolSettings', districtSettings: 'getDistrictSettings' }),
    hideBadgeBar() {
      const distBadgeInfo = this.districtSettings?.find(metaValue => metaValue.key === 'badge_bar') ?? '0'
      const schoolBadgeInfo = this.schoolSettings?.find(metaValue => metaValue.key === 'badge_bar') ?? '0'
      if (distBadgeInfo?.value !== '1' && schoolBadgeInfo?.value !== '1') {
        return false
      }
      return true
    },
    hideStudentStats() {
      return this.districtSettings?.find(metaValue => metaValue.key === 'student_dashboard_stats')?.value === '1'
    },
    visibleSlides() {
      const slides = []
      if ((this.isATeacher || this.isAStudent) && !this.hideBadgeBar) {
        slides.push(0) // Add the first slide
      }
      if (this.marketplaces && this.marketplaces.length) {
        slides.push(1) // Always include the second slide
      }
      //   #todo check condition
      if (!this.hideStudentStats) {
        slides.push(2)
      }
      if (this.showSlpStatistic) {
        slides.push(3)
      }
      return slides
    },
    showSlpStatistic() {
      const schoolSetting = this.schoolSettings?.find(metaValue => metaValue.key === 'student_dashboard')
      const distSetting = this.districtSettings?.find(metaValue => metaValue.key === 'student_dashboard')
      const dashboardSetting = schoolSetting?.value || distSetting?.value
      const studentDashboardRoute = ['student-dashboard', `student-dashboard-for-${this.self.usertype}`]
      if (dashboardSetting === 'slp' && studentDashboardRoute.includes(this.$route.name) && this.statisticsData) {
        return true
      }
      return false
    },
    currentRoute() {
      return this.$route.name
    },
  },
  watch: {
    currentRoute(newVal, oldVal) {
      if (
        !this.isAStudent && !this.refreshRewardStats
        && !(this.rewardsFetchRoutes.includes(newVal) && this.rewardsFetchRoutes.includes(oldVal))
        && (this.rewardsFetchRoutes.includes(newVal) || this.rewardsFetchRoutes.includes(oldVal))
      ) {
        this.getAwardStats()
      }
    },
    refreshRewardStats(val) {
      if (val) this.getAwardStats()
    },
  },
  mounted() {
    this.startCarousel()
    this.getMarketPlaces()
    if (!this.isHighLevel || (this.isHighLevel && this.self.id !== this.getUserId())) this.getAwardStats()
  },
  beforeDestroy() {
    this.stopCarousel()
  },
  methods: {
    ...mapActions('appConfig', ['setRefreshRewardStats']),
    pushToStats({
      title, subtitle, color, icon, fill, type = '',
    }) {
      this.awardStatsData.push({
        type,
        title,
        subtitle,
        color,
        icon,
        fill,
        customClass: 'mb-2 mb-xl-0 px-0',
      })
    },
    getAwardStats() {
      this.awardStatsData = []
      const params = {
        uid: this.getUserId(),
      }

      this.isProccessing = true
      useJwt
        .getStudentRewardCount({ params })
        .then(res => {
          const response = res.data?.data
          // Push to Award Stats
          this.pushToStats({
            type: 'badge',
            title: response.award_stats.badge ?? 0,
            subtitle:
              response.award_stats.badge > 1
                ? i18n.tc('labels.badges')
                : i18n.tc('labels.badge'),
            icon: 'AwardIcon',
            fill: 'transparent',
            color: 'light-warning',
          })

          this.pushToStats({
            type: 'vibe',
            title: response.award_stats.vibe ?? 0,
            subtitle:
              response.award_stats.vibe > 1
                ? i18n.tc('labels.vibes')
                : i18n.tc('labels.vibe'),
            icon: 'TwitchIcon',
            fill: 'transparent',
            color: 'light-danger',
          })

          this.pushToStats({
            type: 'points',
            title: response.award_stats.points ?? 0,
            subtitle: i18n.tc('labels.points'),
            icon: 'CircleIcon',
            fill: '#ff9f43',
            color: 'light-secondary',
          })

          this.pushToStats({
            type: 'coins',
            title: response.award_stats.coins ?? 0,
            subtitle: i18n.tc('labels.coins'),
            icon: 'DollarSignIcon',
            fill: 'transparent',
            color: 'light-success',
          })
        })
        .finally(() => {
          this.isProccessing = false
          this.setRefreshRewardStats(false)
        })
    },
    getUserId() {
      switch (this.$route.name) {
        case 'user-profile':
          /* eslint-disable prefer-destructuring */
          return Number(this.$route.params.name.split('_')[1])
        case `student-dashboard-for-${this.self.usertype}`:
        case `${this.self.usertype}-student-diary`:
          return Number(this.$route.params.id)
        case 'rewards-detail':
          return this.$route.params.uid ? this.$route.params.uid : this.self.id
        default:
          return this.self.id
      }
    },
    getMarketPlaces() {
      this.isProcessing = true
      // const schoolId = this.self.id
      useJwt.getMarketPlaces().then(response => {
        this.marketplaces = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    startCarousel() {
      this.interval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.visibleSlides.length
      }, 10000)
    },
    visibleIndex(index) {
      return this.visibleSlides.indexOf(index)
    },
    stopCarousel() {
      clearInterval(this.interval)
    },
    pauseCarousel() {
      this.stopCarousel() // Stop the interval when hovering
    },
    resumeCarousel() {
      this.startCarousel() // Resume the interval when hover ends
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.visibleSlides.length
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.visibleSlides.length) % this.visibleSlides.length
    },
    goToSlide(index) {
      this.currentIndex = index
    },
  },
}
</script>
<style>
.carousel .card-statistics{
  height: 80px;
  width: 100%;
}
.dark-layout .carousel-container {
  background: #283046;
}
.carousel-container {
  position: relative;
  width: 100%; /* Adjust to your desired width */
  height: 80px; /* Adjust to your desired height */
  overflow: hidden;
  background: #fff;
}

.carousel {
  display: flex;
  position: relative;
  height: 100%;
  width:100%
}

.carousel-item {
  flex: 0 0 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  backface-visibility: hidden;
  opacity: 0;
  transition: transform 2s ease-in-out, opacity 2s ease-in-out;
  box-sizing: border-box;
}

.carousel-item.active {
  transform: translateY(0); /* Flip up into view */
  opacity: 1; /* Fade in */
  z-index: 1;
}

/* Optional: Ensure new items are ready at bottom */
.carousel-item:not(.active):not(.previous) {
  transform: translateY(-50px);
}
.carousel-arrow {
  position: absolute;
  right: 2%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 2px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
}

.carousel-arrow.up {
  top: 3px;
}

.carousel-arrow.down {
  bottom: 3px;
}
.carousel-container:hover .carousel-arrow,
.carousel-container:hover .carousel-indicators {
  opacity: 0.8; /* Show arrows and indicators on hover */
}
.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  position: absolute;
  bottom: 10px; /* Adjust position */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
}

.indicator {
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.indicator.active {
  background: rgba(0, 0, 0, 0.8);
}
#shakemessage {
  background-color: #ffffff;
  border-radius: 5px;
  color: #272727;
  font-family: "Patua One", cursive;
  font-size: 1.5em;
  text-align: center;
  text-transform: uppercase;
  animation: shake 5s;
  padding:8px;
  cursor:pointer;
  animation-iteration-count: infinite;
  width: 300px;
  margin: auto;
  margin-top:10px;
}

.dark-layout #shakemessage {
  color: #fff;
  border: 0 !important;
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}
.carousel .marketplace-card{
  margin-left:0px !important;
}
@media(max-width:768px) {
  .carousel .stats-detail {
    display: block;
  }
}
</style>
