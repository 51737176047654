<template>
  <b-nav-item-dropdown
    v-if="currentLocale"
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale?.img"
        height="14px"
        width="22px"
        :alt="currentLocale?.locale"
      />
      <span class="ml-50 d-md-inline-block d-none text-body">{{ currentLocale?.locale.toUpperCase() }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLang(localeObj)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import locales from '@/const/locales'
import { getCurrentInstance } from 'vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  props: {
    defaultLocale: {
      type: String,
      default: '',
    },
  },
  setup() {
    const instance = getCurrentInstance().proxy

    const changeLang = localeObj => {
      instance.$i18n.locale = localeObj.locale
      if (!instance.defaultLocale) {
        window.location.reload()
      }
    }

    return {
      locales,
      changeLang,
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      if (useJwt.getToken()) {
        useJwt.setLanguage({
          language: this.$i18n.locale,
        })
      }
      const locale = this.$i18n.locale || this.defaultLocale || 'en'
      localStorage.setItem('locale', locale)
      return this.locales.find(l => l.locale === locale) || this.locales.find(l => l.locale === 'en')
    },
  },
  mounted() {
    if (this.defaultLocale) {
      this.changeLang({
        locale: this.defaultLocale,
      })
    }
  },
}
</script>

<style scoped>

</style>
