<template>
  <div :class="className">
    <div class="content-overlay" />
    <!-- <div class="header-navbar-shadow" /> -->
    <div
      class="content-wrapper"
      :class="[contentWidth === 'boxed' ? 'container p-0' : null, isNoteExpanded && 'one-third-view-for-notes ml-2']"
    >
      <!-- Award Statistics  -->
      <!-- <b-overlay
        v-if="(isATeacher || isAStudent) && !hideBadgeBar"
        :show="isProccessing"
        variant="light-secondary"
      >
        <statistic-card
          :card-options="{
            title: 'award-statistics',
          }"
          :data="awardStatsData"
          :student-id="getUserId()"
        />
      </b-overlay> -->
      <b-overlay
        v-if="(isATeacher || isAStudent) && !hideBadgeBar"
        :show="isProccessing"
        class="mb-1 statistic-carousel"
      >
        <statistic-carousel />
      </b-overlay>

      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div
        class="content-body-responsive"
      >
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>

    <!-- Self Evaluation Modal -->
    <self-evaluation-modal
      v-if="isAStudent"
      :user="self"
    />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
// import StatisticCard from '@/views/common/dashboard/StatisticCard.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import StatisticCarousel from '@/views/common/dashboard/StatisticCarousel.vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import {
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
  USER_TYPE_PARENT,
  USER_TYPE_SCHOOL,
} from '@/const/userType'
import GEC_EVENT_BUS from '@/utils/eventBus'
import SelfEvaluationModal from './components/SelfEvaluationModal.vue'

export default {
  components: {
    AppBreadcrumb,
    // StatisticCard,
    BOverlay,
    SelfEvaluationModal,
    StatisticCarousel,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition,
      contentWidth,
      isAStudent: getUserData() && getUserData().usertype === USER_TYPE_STUDENT,
      isATeacher: getUserData() && getUserData().usertype === USER_TYPE_TEACHER,
      isAParent: getUserData() && getUserData().usertype === USER_TYPE_PARENT,
      isASchool: getUserData() && getUserData().usertype === USER_TYPE_SCHOOL,
    }
  },
  data() {
    return {
      awardStatsData: [],
      isProccessing: false,
      self: getUserData(),
      isHighLevel: isHighLevelUser(),
      rewardsFetchRoutes: [
        'user-profile',
        `student-dashboard-for-${getUserData().usertype}`,
        'rewards-detail',
        `${getUserData().usertype}-student-diary`,
      ],
      currentIndex: 0,
      items: ['Slide 1', 'Slide 2', 'Slide 3', 'Slide 4'], // Your slides content
      interval: null,
    }
  },
  computed: {
    ...mapGetters('appConfig', { refreshRewardStats: 'getRefreshRewardStats', schoolSettings: 'getSchoolSettings', districtSettings: 'getDistrictSettings' }),
    ui() {
      return this.$store.state.appConfig.layout.ui
    },

    isModernUI() {
      return this.ui === 'modern'
    },

    className() {
      let className = this.$route.meta.contentClass

      const fullWidthClass = ' align-items-center new-content justify-content-center px-md-5 p-1 py-md-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 mr-auto ml-auto '

      className
        += (this.isAStudent
          || this.isATeacher
          || this.isAParent
          || this.isASchool)
        && this.isModernUI
          ? fullWidthClass
          : ' app-content content '
      if (this.$store.state.app.shallShowOverlay) {
        className += ' show-overlay '
      }
      if (this.isNoteExpanded) {
        className = className.replace('ml-auto', '')
      }
      return className
    },
    currentRoute() {
      return this.$route.name
    },
    isNoteExpanded() {
      return this.currentRoute === 'student-problems' && this.$store.state.studentNotes.isExpanded
    },

    // hideBadgeBarStatus() {
    //   const { schoolInfo } = this.$store.state.appConfig
    //   const badgeInfo = schoolInfo?.user_meta?.find(metaValue => metaValue.key === 'badge_bar')
    //   let badgeBarValue = '0'
    //   if (badgeInfo) {
    //     badgeBarValue = badgeInfo.value
    //   }
    //   return badgeBarValue
    // },
    hideBadgeBar() {
      const distBadgeInfo = this.districtSettings?.find(metaValue => metaValue.key === 'badge_bar') ?? '0'
      const schoolBadgeInfo = this.schoolSettings?.find(metaValue => metaValue.key === 'badge_bar') ?? '0'
      if (distBadgeInfo?.value !== '1' && schoolBadgeInfo?.value !== '1') {
        return false
      }
      return true
    },
  },
  watch: {
    currentRoute(newVal, oldVal) {
      if (
        !this.isAStudent && !this.refreshRewardStats
        && !(this.rewardsFetchRoutes.includes(newVal) && this.rewardsFetchRoutes.includes(oldVal))
        && (this.rewardsFetchRoutes.includes(newVal) || this.rewardsFetchRoutes.includes(oldVal))
      ) {
        this.getAwardStats()
      }
      // const routeCheckForStudentLab = [
      //   'teacher-view-students', 'teacher-lessons', 'teacher-lesson', 'teacher-problems',
      //   'teacher-class-calendar', 'teacher-class-test', 'student-essay', 'teacher-class',
      //   'teacher-monitor-class', 'teacher-higher-level-report',
      //   'student-view-students', 'student-lessons', 'student-lesson', 'student-problems',
      //   'student-class',
      // ]
      // if (routeCheckForStudentLab.includes(newVal)) {
      //   const schoolData = JSON.parse(localStorage.getItem('schoolData'))
      //   this.hideBadgeBar = schoolData.badge_bar || '0'
      // } else {
      //   this.hideBadgeBar = '0'
      // }
    },
    refreshRewardStats(val) {
      if (val && !this.isProccessing) this.getAwardStats()
    },
  },
  beforeCreate() {
    useJwt.getSchoolAndDistrictSettings().then(response => {
      const { school, district } = response.data.data

      const schoolInfo = {
        ...this.schoolInfo,
        user_meta: school,
      }

      const districtInfo = {
        ...this.districtInfo,
        user_meta: district,
      }

      this.$store.dispatch('appConfig/updateSchoolDistrictInfo', { schoolInfo, districtInfo })
      GEC_EVENT_BUS.$emit('schoolDistrictConfigReady')
    })
  },
  mounted() {
    if (!this.isHighLevel || (this.isHighLevel && this.self.id !== this.getUserId())) this.getAwardStats()
  },
  methods: {
    ...mapActions('appConfig', ['setRefreshRewardStats']),
    pushToStats({
      title, subtitle, color, icon, fill, type = '',
    }) {
      this.awardStatsData.push({
        type,
        title,
        subtitle,
        color,
        icon,
        fill,
        customClass: 'mb-2 mb-xl-0',
      })
    },
    getAwardStats() {
      this.awardStatsData = []
      const params = {
        uid: this.getUserId(),
      }

      this.isProccessing = true
      useJwt
        .getStudentRewardCount({ params })
        .then(res => {
          const response = res.data?.data
          // Push to Award Stats
          this.pushToStats({
            type: 'badge',
            title: response.award_stats.badge ?? 0,
            subtitle:
              response.award_stats.badge > 1
                ? i18n.tc('labels.badges')
                : i18n.tc('labels.badge'),
            icon: 'AwardIcon',
            fill: 'transparent',
            color: 'light-warning',
          })

          this.pushToStats({
            type: 'vibe',
            title: response.award_stats.vibe ?? 0,
            subtitle:
              response.award_stats.vibe > 1
                ? i18n.tc('labels.vibes')
                : i18n.tc('labels.vibe'),
            icon: 'TwitchIcon',
            fill: 'transparent',
            color: 'light-danger',
          })

          this.pushToStats({
            type: 'points',
            title: response.award_stats.points ?? 0,
            subtitle: i18n.tc('labels.points'),
            icon: 'CircleIcon',
            fill: '#ff9f43',
            color: 'light-secondary',
          })

          this.pushToStats({
            type: 'coins',
            title: response.award_stats.coins ?? 0,
            subtitle: i18n.tc('labels.coins'),
            icon: 'DollarSignIcon',
            fill: 'transparent',
            color: 'light-success',
          })
        })
        .finally(() => {
          this.isProccessing = false
          this.setRefreshRewardStats(false)
        })
    },
    getUserId() {
      switch (this.$route.name) {
        case 'user-profile':
          /* eslint-disable prefer-destructuring */
          return Number(this.$route.params.name.split('_')[1])
        case `student-dashboard-for-${this.self.usertype}`:
        case `${this.self.usertype}-student-diary`:
          return Number(this.$route.params.id)
        case 'rewards-detail':
          return this.$route.params.uid ? this.$route.params.uid : this.self.id
        default:
          return this.self.id
      }
    },
  },
}
</script>

<style>
  .one-third-view-for-notes {
    position: absolute;
    left: 0;
    width: 79%;
  }

  @media(width:768px) {
    .content-header,.content-body, .content-body-responsive{
        margin-top:10px !important;
    }
  }
</style>
